import React from "react";
import { fbApp } from '../adapters/firebase';
import { useAuth } from '../contexts/AuthContext';


export const usePendingData = () => {
  const { currentUser } = useAuth();
  const [pendingOrders, setPendingOrders] = React.useState([]);
  const db = fbApp.firestore();
  React.useEffect( () => {
    db.collection('users').doc(currentUser.uid).collection('pendingOrders').get().then(snap => {
      setPendingOrders(snap.size) // will return the collection size
  });

  }, [db]);
  return pendingOrders;
};
